.editorClassName {
  height: 300px;
  border-radius: 2px;
  border: 1px solid #f1f1f1;
}

.viewFieldHtml a {
  color: #2475ff;
  cursor: pointer;
}

.viewFieldHtml:hover a:hover {
  color: black;
}
