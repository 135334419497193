.crop {
  height: 150px;
  width: 200px;
  overflow: hidden;
}
.crop img {
  width: 100%;
  height: auto;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%); /* Ch <36, Saf 5.1+, iOS < 9.2, An =<4.4.4 */
  -ms-transform: translateY(-50%); /* IE 9 */
  transform: translateY(-50%); /* IE 10, Fx 16+, Op 12.1+ */
}

.bigAvatar {
  width: 100px !important;
  height: 100px !important;
}

.mediumAvatar {
  width: 60px !important;
  height: 60px !important;
}

.smallAvatar {
  width: 19px !important;
  height: 19px !important;
}

.small25Avatar {
  width: 25px !important;
  height: 25px !important;
}
