/* .paperDrawerWidthMobile {
    width: 50px;
    height: 100% !important;
    position: relative !important;
}

.paperDrawerWidthDesktop {
    width: 180px;
    height: 100% !important;
    position: relative !important;
} */

/* .overlay {
  position: absolute;    
  height:100px;
  background-color: gray;
  height:100%;
  opacity: .8;
  z-index:9999999999
} */

/* .:hover {
  background-color: #edf1f3 !important;
} */

.columnMenuDrawer {
  margin-right: 20px;
  margin-left: 20px;
}

.paperMenuLeft {
  width: 200px;
}

.parentDropDownMenu:hover {
  background-color: #dee4e7;
}
.parentDropDownMenu:hover .dropdownIconArrow {
  color: #263f53 !important;
}

/* .:hover .iconDrawer {
  color: #263f53 !important;
} */
/* .iconDrawer:hover {
  color: white !important;
} */
.listItemIconClass {
  /* margin-left: 10px; */
  min-width: 35px !important;
}
.menuParentNotSelected:hover {
  font-weight: bold;
  color: #1445fe;
  font-size: 0.78rem !important;
  background-color: #faf8f7;
  border-radius: 20px;
}
.menuParentSelected {
  font-weight: bold;
  /* color: #263f53 !important; */
  /* color: white !important; */
  /* font-size: 20px !important; */
  font-size: 0.78rem !important;
  background-color: #2475ff;
  color: white;
  border-radius: 20px;
}

.menuProfileNotSelected:hover {
  font-weight: bold;
  color: #1445fe;
  font-size: 0.78rem !important;
  background-color: #faf8f7;

  /* color: white; */
  border-radius: 20px;
}

.menuListItemHeight {
  height: 42px;
  margin-bottom: 0px !important;
  /* height: 38px !important; */
  /* paddingleft: '20px'; */
  /* border-left: '5px solid #CBCBF1' !important; */
}

.paperDrawerLeft {
  height: 100% !important;
  position: relative !important;
}

.ListItemDrawer {
  padding-top: 3px !important;
}
.ListItemIconDrawer {
  margin-left: 10px;
  min-width: 35px !important;
}
.iconDrawer {
  font-size: 1.2rem !important;
}

/* . {
  color: #4e718d !important;
} */
/* #4e718d; */
/* .rootDrawerLeft {
  height: 100%;
} */
.mainDrawer {
  flex-grow: 1;
  width: 0px;
  /* overflow-y: scroll; ERROR WINDOWS CHROME*/
  /* min-width: 0; */
  /* padding-left: 5px; */
}
.menuSelected {
  color: white;
}

.menuDrawerLeft {
  font-size: 0.75rem !important;
}
.menuDrawerLeftSecondary {
  /* color: white !important; */
  font-size: 0.75rem !important;
}
/* .menuDrawerLeftSecondary {
  max-width: 150px;
} */

/* .drawerLeft {
  margin-top: 6px;
  min-width: 170px;
} */

/* .drawerLeft {
    margin-top: 6px;
    width: 900px;
} */

.root {
  flex-grow: 1;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  position: relative;
  display: flex;
}

.titleDrawer {
  text-align: center;
  margin-top: 10px;
  font-weight: 600;
}

.userDrawerClass {
  min-height: 100vh;
}

.centralDivider {
  margin: 6%;
}
