/* @import 'https://fonts.googleapis.com/css?family=Fira+Sans';
@import 'https://fonts.googleapis.com/css?family=Quicksand';
@import 'https://fonts.googleapis.com/css?family=Source+Sans+Pro'; */

/* @import 'https://fonts.googleapis.com/css?family=Mukta'; */

@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
/* @import 'https://fonts.googleapis.com/css?family=Noticia+Text'; */

/* @import 'https://fonts.googleapis.com/css?family=Roboto'; */

@font-face {
  font-family: 'Credit-Card-Font';
  src: url('./fonts/Credit-Card-Font-NachoNacho.woff');
  /* font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal; */
}
@font-face {
  font-family: 'new-kansas';
  src: url('./fonts/KansasNew-Regular.otf');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}
@font-face {
  font-family: 'new-kansas';
  src: url('./fonts/KansasNew-Bold.otf');
  font-display: auto;
  font-style: bold;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: 'Roobert';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Roobert-Regular.ttf');
}
@font-face {
  font-family: 'Roobert';
  font-style: bold;
  font-weight: 700;
  src: url('./fonts/Roobert-Bold.ttf');
}

.fontRoobert {
  font-family: 'Roobert' !important;
}
b {
  font-weight: 900;
}

.fw-700 {
  font-weight: 700;
}

:root {
  --light-blue: #2475ff;
  --blue: #1445fe;
  --dark-blue: #060452;
  --yellow: #fae83c;
  --white: #ffffff;
  --light-grey-1: #858580;
  --light-grey-2: #d3d3d3;
  --light-grey-3: #f4f1ee;
  --dark-grey-1: #5e5e5e;
  --nachoblack: #5e5e5e;
  --black: #161616;
  --green: #159312;
  --red: #f7315a;
  --orange: #ff7a00;
}

.link {
  color: var(--blue);
  cursor: pointer;
  font-weight: 700;
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer;
}

.tac {
  text-align: center;
}

.m-0 {
  margin: 0;
}

.d-inline {
  display: inline;
}

.h-100 {
  height: 100%;
}

/* Font sizes */
.fs-10 {
  font-size: 10px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-21 {
  font-size: 21px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.fs-32 {
  font-size: 32px !important;
}

/* Font weights */
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}

/* Font colors */
.text-dark-blue {
  color: var(--dark-blue) !important;
}
.text-blue {
  color: var(--blue) !important;
}
.text-light-blue {
  color: var(--light-blue) !important;
}
.text-yellow {
  color: var(--yellow) !important;
}
.text-white {
  color: var(--white) !important;
}
.text-light-grey-1 {
  color: var(--light-grey-1) !important;
}
.text-light-grey-2 {
  color: var(--light-grey-2) !important;
}
.text-light-grey-3 {
  color: var(--light-grey-3) !important;
}
.text-dark-grey-1 {
  color: var(--dark-grey-1) !important;
}
.text-black {
  color: var(--black) !important;
}
.text-nachoblack {
  color: var(--nachoblack) !important;
}
.text-green {
  color: var(--green) !important;
}
.text-red {
  color: var(--red) !important;
}
.text-orange {
  color: var(--orange) !important;
}

/* Svg colors */
svg.dark-blue {
  fill: var(--dark-blue) !important;
}
svg.blue {
  fill: var(--blue) !important;
}
svg.light-blue {
  fill: var(--light-blue) !important;
}
svg.yellow {
  fill: var(--yellow) !important;
}
svg.white {
  fill: var(--white) !important;
}
svg.light-grey-1 {
  fill: var(--light-grey-1) !important;
}
svg.light-grey-2 {
  fill: var(--light-grey-2) !important;
}
svg.light-grey-3 {
  fill: var(--light-grey-3) !important;
}
svg.dark-grey-1 {
  fill: var(--dark-grey-1) !important;
}
svg.black {
  fill: var(--black) !important;
}
svg.nachoblack {
  fill: var(--nachoblack) !important;
}
svg.green {
  fill: var(--green) !important;
}
svg.red {
  fill: var(--red) !important;
}
svg.orange {
  fill: var(--orange) !important;
}

/* Background colors */
.bg-dark-blue {
  background-color: var(--dark-blue) !important;
}
.bg-blue {
  background-color: var(--blue) !important;
}
.bg-light-blue {
  background-color: var(--light-blue) !important;
}
.bg-yellow {
  background-color: var(--yellow) !important;
}
.bg-white {
  background-color: var(--white) !important;
}
.bg-light-grey-1 {
  background-color: var(--light-grey-1) !important;
}
.bg-light-grey-2 {
  background-color: var(--light-grey-2) !important;
}
.bg-light-grey-3 {
  background-color: var(--light-grey-3) !important;
}
.bg-dark-grey-1 {
  background-color: var(--dark-grey-1) !important;
}
.bg-black {
  background-color: var(--black) !important;
}
.test {
  background-color: #f4f1ee !important;
}

@media print {
  /* @page {
    margin: 0.1px;
  } */
  /*
  body {
    margin: 1.6cm;
  } */

  .no-print,
  .no-print * {
    display: none !important;
  }
}
/* ::-webkit-scrollbar {
  width: 0px;
  background: transparent;
} */

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  /* scroll-behavior: smooth; */
}

body {
  font-size: 0.875rem;
  font-weight: 200;
  color: #676767;
  font-family: 'Roobert', sans-serif;
}

.public-DraftStyleDefault-block {
  margin: 0px 0 !important;
}

h1,
h2 {
  color: #1d2738;
}

h1 {
  font-size: 1.6rem;
  /* margin: 10px; */
  /* font-weight: 400; */
  font-family: 'new-kansas', sans-serif;
}

h2 {
  font-size: 1.4rem;
  font-weight: 400;
  font-family: 'new-kansas', sans-serif;
}

h3 {
  color: #1d2738;
  font-family: 'Roobert', sans-serif;
  font-weight: 700;
  font-size: 18px;
  margin-top: 8px;
  margin-bottom: 8px;
}
h4 {
  margin-bottom: 5px;
  margin-top: 5px;
  font-weight: 600;
  font-size: 15px;
  font-family: 'Roobert', sans-serif;
}

a {
  text-decoration: none;
  color: #676767;
}

.underline {
  text-decoration: underline;
}

.grey {
  color: #676767;
}

.white {
  color: #ffffff;
}

.titlePage {
  margin: 10px 0px;
}

textarea,
select,
input,
button,
li {
  outline: none;
  margin-bottom: 10px;
}

.spaceForm {
  margin-top: 20px !important;
}
.inputNumber {
  height: auto !important;
}

button {
  text-transform: none !important;
}

/* li {
    margin-bottom: -10px;
} */

.liClass {
  margin: 10px 0;
}

.MuiSelect-select .listView {
  display: none;
}
.MuiMenuItem-root .selectedView {
  display: none;
}

strong {
  font-weight: 900 !important;
}

.fontWeight19 {
  font-weight: 900 !important;
}

.fontWeight18 {
  font-weight: 800 !important;
}

.fontWeight16 {
  font-weight: 600 !important;
}

.fontWeight15 {
  font-weight: 500 !important;
}

.fontWeight14 {
  font-weight: 400 !important;
}

.fontWeight13 {
  font-weight: 300 !important;
}

.fontWeight12 {
  font-weight: 200 !important;
}

.fontWeight11 {
  font-weight: 150 !important;
}

.fontWeight10 {
  font-weight: 100 !important;
}

.textSize20 {
  font-size: 5rem !important;
}

.textSize16 {
  font-size: 3.8rem;
}

.textSize15 {
  font-size: 3.5rem !important;
}

.textSize14 {
  font-size: 2.7rem !important;
}

.textSize13 {
  font-size: 2.3rem !important;
}

.textSize12 {
  font-size: 1.5rem !important;
}

.textSize11 {
  font-size: 1.15rem !important;
}

.textSize10 {
  font-size: 1rem !important;
}

.textSize9 {
  font-size: 0.92rem !important;
}

.textSize8 {
  font-size: 0.88rem !important;
}

.textSize7 {
  font-size: 0.8rem !important;
}

.textSize6 {
  font-size: 0.7rem !important;
}

.textSize5 {
  font-size: 0.6rem !important;
}

.textSize3 {
  font-size: 0.5rem !important;
}

.textSize2 {
  font-size: 0.35rem;
}

.margin0 {
  margin: 0px;
}

.margin1 {
  margin: 5px;
}

.margin2 {
  margin: 10px;
}

.margin3 {
  margin: 15px;
}

.margin4 {
  margin: 20px;
}

.margin5 {
  margin: 30px;
}

.margin6 {
  margin: 40px;
}

.margin7 {
  margin: 60px;
}

.margin8 {
  margin: 80px;
}

.margin9 {
  margin: 100px;
}

.margin10 {
  margin: 120px;
}

.padding1 {
  padding: 5px;
}

.padding2 {
  padding: 10px;
}

.padding3 {
  padding: 15px;
}

.padding4 {
  padding: 20px;
}

.padding5 {
  padding: 30px;
}

.padding6 {
  padding: 40px;
}

.padding7 {
  padding: 60px;
}

.padding8 {
  padding: 80px;
}

.padding9 {
  padding: 100px;
}

.padding1RightLeft {
  padding-right: 10px;
  padding-left: 10px;
}
.padding2RightLeft {
  padding-right: 20px;
  padding-left: 20px;
}

.padding10RightLeft {
  padding-right: 200px;
  padding-left: 200px;
}

.overflowHidden {
  overflow: hidden;
}

.bold {
  font-weight: bold;
}

.lineThrough {
  text-decoration: line-through;
}

.dividerSection {
  width: 65%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.verySmallImg {
  max-width: 40px;
}

.noWrap {
  white-space: nowrap;
}

.textTransparencyWhiteMax {
  color: rgba(255, 255, 255, 0) !important;
}

.textTransparencyWhite3 {
  color: rgba(255, 255, 255, 0.3) !important;
}

.bgHover:hover {
  background-color: #fafafa !important;
}

.bgHoverOrange {
  background-color: #fff8f2 !important;
}
.bgHoverOrange:hover {
  background-color: #fff2e6 !important;
}

.textTransparencyWhite1 {
  color: rgba(255, 255, 255, 0.7) !important;
}

.flexGrow {
  flex-grow: 1;
}

.flex {
  flex: 1;
}

.marginTopAuto {
  margin-top: auto !important;
}

.floatRight {
  float: right;
}

.m-0 {
  margin: 0px;
}

/* .red {
    color: #e33e7f !important;
} */

.red {
  color: #ff7a00 !important;
}

.link {
  color: #1445fe;
  cursor: pointer;
}
.link:hover {
  color: black;
}

.linkWhite {
  color: white;
  cursor: pointer;
}
.linkWhite:hover {
  color: black;
}

.secondary {
  color: #ff7a00 !important;
}

/* color: #FF7A00 !important; */
.primary {
  color: #1445fe !important;
}
svg.primary {
  fill: #1445fe !important;
}

.nnBlack {
  color: #5e5e5e !important;
}

.primaryBG {
  background-color: #2475ff !important;
}

.reward {
  color: #139313 !important;
}

.black {
  /* color: #676767 !important; */
  color: black;
}
.black2 {
  color: #161616 !important;
}

/* .pink {
  color: #e33e7f !important;
} */

.pink {
  color: #ff7a00 !important;
}

.white {
  color: white !important;
}

.white2 {
  color: rgb(255, 255, 255);
}

.white4 {
  color: rgb(220, 220, 220);
}

.whiteBlue {
  color: rgb(208, 227, 255);
}

.blue {
  color: #2475ff;
}

.bgPurple {
  background-color: #3d137c;
}
.bgYellow {
  background-color: #fae83c;
}

.bgPrimary {
  background-color: #2475ff;
}

.bgSecondary {
  background-color: #ff7a00;
}

.bgPink {
  background-color: #ff7a00;
}

.bgRed {
  background-color: #f7315a;
}

/* .bgPink {
  background-color: #e33e7f;
} */

.bgGrey {
  background-color: #fafafa !important;
}

.bgGrey2 {
  background-color: #f4f1ee !important;
}

.bgGrey3 {
  background-color: #949494 !important;
}
.bgGrey4 {
  background-color: #514a45 !important;
}
.bgGrey5 {
  background-color: #c4c4c4 !important;
}
.bgSalmonGrey {
  background-color: #faf8f7 !important;
}

.grey1 {
  color: rgba(0, 0, 0, 0.1);
}

.grey4 {
  color: rgba(0, 0, 0, 0.4);
}

.grey5 {
  color: rgba(0, 0, 0, 0.5);
}

.grey6 {
  color: rgba(0, 0, 0, 0.6);
}

.grey7 {
  color: rgba(0, 0, 0, 0.7);
}

.grey8 {
  color: rgba(0, 0, 0, 0.8);
}

.grey9 {
  color: rgba(0, 0, 0, 0.9);
}

.italic {
  font-style: italic;
}

.inline {
  display: inline;
}

.hide {
  display: none !important;
}

.tac {
  text-align: center;
}

.tal {
  text-align: left;
}

.tar {
  text-align: right;
}

.justify {
  text-align: justify;
}

.width100per {
  width: 100% !important;
}

.cursor {
  cursor: pointer !important;
}

.cursorDefault {
  cursor: default !important;
}

.noWrap {
  white-space: nowrap;
}

/* #password,
.password {
  height: auto !important;
} */

@media only screen and (min-width: 769px) {
  .singleListMargin {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .paperIn {
    padding: 20px;
  }

  .paperOut {
    margin: 10px 20px;
  }
}

.heightInherit {
  height: inherit !important;
}

.marginAuto {
  margin: auto !important;
}
.marginVerticalAuto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.inputWidth {
  width: 150px;
}

.imageSize5 {
  max-width: 40px;
}
.imageSize10 {
  max-width: 140px;
}

#root {
  /* max-width: 1080px; */
  min-height: 100%;
  margin: auto;
  display: grid;
  /* grid-template-rows: auto 1fr auto;   */
}

@media only screen and (max-width: 768px) {
  .singleListMargin {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .paperIn {
    padding: 10px;
  }

  .paperOut {
    margin: 5px;
  }
}

@media only screen and (min-width: 959px) {
  .hideDesktop {
    display: none;
  }

  .Select,
  .inputResponsive {
    width: 260px !important;
  }
}

@media only screen and (max-width: 960px) {
  .hideMobile {
    display: none;
  }

  .Select,
  .inputResponsive {
    width: 200px;
  }
}

@media (min-width: 1920px) {
  .content {
    padding: 30px 60px 30px 60px;
  }

  .responsiveMargin1 {
    padding-right: 230px;
    padding-left: 230px;
  }
}

@media (min-width: 1440px) and (max-width: 1919px) {
  .content {
    padding: 30px 60px 30px 60px;
  }

  .responsiveMargin1 {
    padding-right: 200px;
    padding-left: 200px;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .content {
    padding: 30px 60px 30px 60px;
  }

  .responsiveMargin1 {
    padding-right: 180px;
    padding-left: 180px;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .content {
    padding: 30px 60px 30px 60px;
  }

  .responsiveMargin1 {
    padding-right: 90px;
    padding-left: 90px;
  }
}

@media (min-width: 960px) and (max-width: 1023px) {
  .content {
    padding: 30px 60px 30px 60px;
  }

  .responsiveMargin1 {
    padding-right: 70px;
    padding-left: 70px;
  }
}

@media (min-width: 840px) and (max-width: 959px) {
  .content {
    padding: 30px 60px 30px 60px;
  }

  .responsiveMargin1 {
    padding-right: 70px;
    padding-left: 70px;
  }
}

@media (min-width: 720px) and (max-width: 839px) {
  .content {
    padding: 30px 60px 30px 60px;
  }

  .responsiveMargin1 {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media (min-width: 600px) and (max-width: 719px) {
  .content {
    padding: 20px 20px 10px 10px;
  }

  .responsiveMargin1 {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media (min-width: 400px) and (max-width: 599px) {
  .content {
    padding: 20px 20px 10px 10px;
  }

  .responsiveMargin1 {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media (min-width: 360px) and (max-width: 399px) {
  .content {
    padding: 20px 20px 10px 10px;
  }

  .responsiveMargin1 {
    padding-right: 12px;
    padding-left: 12px;
  }
}

@media only screen and (max-width: 359px) {
  .content {
    padding: 20px 8px 10px 0px;
  }

  .responsiveMargin1 {
    padding-right: 6px;
    padding-left: 6px;
  }
}

/* /////////////////////// */

@media (min-width: 1920px) {
  .responsiveMargin2 {
    padding-right: 300px;
    padding-left: 300px;
  }
}

@media (min-width: 1440px) and (max-width: 1919px) {
  .responsiveMargin2 {
    padding-right: 250px;
    padding-left: 250px;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .responsiveMargin2 {
    padding-right: 180px;
    padding-left: 180px;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .responsiveMargin2 {
    padding-right: 170px;
    padding-left: 170px;
  }
}

@media (min-width: 960px) and (max-width: 1023px) {
  .responsiveMargin2 {
    padding-right: 130px;
    padding-left: 130px;
  }
}

@media (min-width: 840px) and (max-width: 959px) {
  .responsiveMargin2 {
    padding-right: 100px;
    padding-left: 100px;
  }
}

@media (min-width: 720px) and (max-width: 839px) {
  .responsiveMargin2 {
    padding-right: 80px;
    padding-left: 80px;
  }
}

@media (min-width: 600px) and (max-width: 719px) {
  .responsiveMargin2 {
    padding-right: 80px;
    padding-left: 80px;
  }
}

@media (min-width: 400px) and (max-width: 599px) {
  .responsiveMargin2 {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media (min-width: 360px) and (max-width: 399px) {
  .responsiveMargin2 {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media only screen and (max-width: 359px) {
  .responsiveMargin2 {
    padding-right: 10px;
    padding-left: 10px;
  }
}

/* /////// */

@media (min-width: 1920px) {
  .imageSection {
    max-width: 70%;
  }

  .marginVerticalResponsive {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@media (min-width: 1440px) and (max-width: 1919px) {
  .marginVerticalResponsive {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .marginVerticalResponsive {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .marginVerticalResponsive {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@media (min-width: 840px) and (max-width: 1023px) {
  .marginVerticalResponsive {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@media (min-width: 720px) and (max-width: 839px) {
  .marginVerticalResponsive {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@media (min-width: 600px) and (max-width: 719px) {
  .marginVerticalResponsive {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@media (min-width: 400px) and (max-width: 599px) {
  .marginVerticalResponsive {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@media (min-width: 360px) and (max-width: 399px) {
  .marginVerticalResponsive {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 359px) {
  .marginVerticalResponsive {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

pre {
  white-space: pre-wrap;
  /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}

.tableInner {
  display: inline-block;
  width: 14em;
  /* height: 6em; */
  margin: 0.5em;
  padding: 3px;
  white-space: normal;
  text-align: center;
}

.circleStyle {
  height: 40px;
  width: 40px;
  font-size: 30px;
  background-color: #ff7a00;
  border-radius: 50%;
  display: inline-block;
  line-height: 40px;
  text-align: center;
  color: white;
}

.hideScrollBar {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent; /* optional */
  }
}

.iconAlignText {
  vertical-align: middle !important;
}

.iconAlignTextBottom {
  vertical-align: bottom !important;
}

.marginTop1 {
  margin-top: 10px;
}

.headerCategories {
  margin-top: 5px;
  background-color: #f5f7f7 !important;
  font-size: 40px;
}

.titleStepKYC {
  font-family: Roobert;
  font-size: 21px;
  font-weight: bold;
  text-align: center;
}

.tabHeaderCategories {
  font-size: 16px;
}

/* .tabSettings {
  flex-direction: row !important;
  width: auto !important;
  text-align: left !important;
} */

.paddingMenuItem {
  padding: 10px !important;
}

.noMinHeight {
  min-height: unset !important;
}

/* for chrome */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* for mozilla */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.seeAllClass {
  margin-left: 10px;
}

.arrowRight {
  padding-top: 5px;
}

.dialogContentRedeem {
  z-index: 99999 !important;
}

.titleDecoration {
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #e0e0e0;
  color: #676767;
  font-size: 13px;
  font-weight: 500;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mr-5 {
  margin-right: 5px;
}

.flex-center {
  display: flex;
  align-items: center;
}

.public-DraftEditor-content {
  border: 1px solid white;
}

@media (min-width: 1920px) {
  .logoNachoNacho {
    height: 55px;
  }
  .logoTechSoup {
    width: 150px;
  }
}

@media (min-width: 1440px) and (max-width: 1919px) {
  .logoNachoNacho {
    height: 55px;
  }
  .logoTechSoup {
    width: 150px;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .logoNachoNacho {
    height: 55px;
  }
  .logoTechSoup {
    width: 150px;
  }
}

@media (min-width: 840px) and (max-width: 1023px) {
  .logoNachoNacho {
    height: 55px;
  }
  .logoTechSoup {
    width: 120px;
  }
}

@media (min-width: 720px) and (max-width: 839px) {
  .logoNachoNacho {
    height: 50px;
  }
  .logoTechSoup {
    width: 111px;
  }
}

@media (min-width: 600px) and (max-width: 719px) {
  .logoNachoNacho {
    height: 40px;
  }
  .logoTechSoup {
    width: 99px;
  }
}

@media (min-width: 480px) and (max-width: 599px) {
  .logoNachoNacho {
    height: 40px;
  }
  .logoTechSoup {
    width: 99px;
  }
}

@media (min-width: 400px) and (max-width: 479px) {
  .logoNachoNacho {
    height: 30px;
  }
  .logoTechSoup {
    width: 99px;
  }
}

@media (min-width: 360px) and (max-width: 399px) {
  .logoNachoNacho {
    height: 30px;
  }
  .logoTechSoup {
    width: 99px;
  }
}

@media only screen and (max-width: 359px) {
  .logoNachoNacho {
    height: 25px;
  }
  .logoTechSoup {
    width: 99px;
  }
}

.loading {
  display: inline-block;
  clip-path: inset(0 2ch 0 0);
  animation: l 1s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0);
  }
}
